import { Trans } from 'react-i18next';

import { CheckboxField } from '~/features/form/field/form-field/checkbox-field';

export const StaySignedIn = ({ className }: { className?: string }) => (
  <CheckboxField
    className={className}
    name='persistent'
    label={
      <Trans ns='field' i18nKey='stay-signed-id'>
        Stay signed in
      </Trans>
    }
  />
);
