import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node';
import { json, redirect } from '@remix-run/node';
import { Link, Outlet, useFetcher, useLoaderData } from '@remix-run/react';
import type { AxiosResponse } from 'axios';
import type { PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import { twc } from 'react-twc';
import { $path } from 'remix-routes';
import { z } from 'zod';

import { authenticationMethods, isConnected, login } from '~/api';

import { Button } from '~/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '~/components/ui/card';

import { ApplicationIcon } from '~/components/application-icons';
import { Form, Password, StaySignedIn, Username } from '~/features/form';
import { logger } from '~/server/logging';
import type { ToastMessage } from '~/server/toast';

import { getValidatedFormDataWithResponse } from '~/server/get-validated-form-data';
import { handleRemoteQuery } from '~/server/handle-remote-query';

import { ExternalLoginProviders } from './external-login-providers';
import { LoginButton } from './login-button';

const schema = z.object({
  userName: z.string(),
  password: z.string(),
  persistent: z.boolean().default(false),
});

const ns = 'login' satisfies Ns;
export const handle = { i18n: [ns] };
export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const { i18next } = context;

  const isAuthenticated = await isConnected(context.axiosOptions);
  if (isAuthenticated) {
    logger.info('User is authenticated, redirecting to home page...');
    throw redirect($path('/'));
  }

  logger.silly('Getting authentication methods...');
  const authMethods = await authenticationMethods(context.axiosOptions);

  const error = new URL(request.url).searchParams.get('error');
  const locale = await i18next.getLocale(request);
  const t = await i18next.getFixedT<typeof ns>(locale, ns);
  if (error) {
    logger.info('An error occurred while logging in: %s', error);
    const title = t('toast.login.title', 'Login');
    const text = t('toast.login.error.text.complex', 'An error occurred while logging in: \n {{error}}', {
      error,
    }) as string;

    context.showToast({
      title,
      text,
      variant: 'destructive',
      iconType: 'user',
    });
    throw redirect($path('/login'));
  }

  const defaultValue: z.infer<typeof schema> = import.meta.env.DEV
    ? {
        userName: 'administrator@localhost',
        password: '4txtU4C5Z9bRDS3Lk9hd8kc9fbU6rMSttwu8ZbZ2ScydWyYMW4ULHdv4aUz5LMze',
        persistent: false,
      }
    : {
        userName: '',
        password: '',
        persistent: false,
      };

  return json({
    defaultValue,
    authMethods,
  });
};

export const action = async ({ request, context }: ActionFunctionArgs) => {
  const { i18next } = context;
  const t = await i18next.getFixedT(request, ns);
  const title = t('toast.login.title', 'Login');
  const { data, response } = await getValidatedFormDataWithResponse(request, context, schema, {
    title,
    text: t('form:toast.validation.error', 'There is an error in the form.'),
    iconType: 'user',
    variant: 'destructive',
  });
  if (response) return response;
  return await handleRemoteQuery(
    context,
    async () => {
      logger.info('Login in user %s', data.userName);
      const response = (await login(data, {
        ...context.axiosOptions,
        responseType: 'blob',
      })) as unknown as AxiosResponse<void>;

      const setCookiesHeader = response.headers['set-cookie'] ?? [];
      const setCookies = Array.isArray(setCookiesHeader) ? setCookiesHeader.join(';') : (setCookiesHeader as string);

      // TODO: check if the setCookies here is required or not
      context.showToast({
        title: t('toast.login.success.title', 'Connected'),
        text: t('toast.login.success.text', 'You are going to be redirected'),
        iconType: 'user',
        variant: 'default',
      });
      return redirect($path('/'), {
        headers: {
          'Set-Cookie': setCookies,
        },
      });
    },
    error => {
      if (typeof error === 'string')
        return {
          title: t('toast.login.error.title', 'Login'),
          text: t('toast.login.error.text.complex', 'An error occurred while logging in: \n {{error}}', { error }),
          variant: 'destructive',
          iconType: 'login',
        } satisfies ToastMessage;
      return {
        title: t('toast.login.error.title', 'Login'),
        text: t('toast.login.error.text.simple', 'An error occurred while logging in.'),
        variant: 'destructive',
        iconType: 'login',
      } satisfies ToastMessage;
    }
  );
};

const Main = twc.main`container [min-block-size:max(460px,100vh)] mx-auto flex flex-col items-stretch justify-center py-10 lg:w-[600px]`;
const LoginLayout = ({ children }: PropsWithChildren) => (
  <Main>
    <Card className='border-none bg-transparent shadow-none hover:shadow-none'>
      <CardHeader>
        <CardTitle className='flex flex-col items-center gap-4'>
          <ApplicationIcon icon='user' />
          <Trans ns={ns} i18nKey='card.title'>
            Welcome to Invoicify
          </Trans>
        </CardTitle>
        <CardDescription className='text-center'>
          <Trans ns={ns} i18nKey='card.sub-title'>
            Your entry into Invoicify app.
          </Trans>
        </CardDescription>
      </CardHeader>
      <CardContent className='p-0 lg:p-6 lg:pt-0'>
        <div className='flex flex-col align-middle'>
          <div className='m-4 lg:min-w-[400px]'>{children}</div>
        </div>
      </CardContent>
    </Card>
    <Outlet />
  </Main>
);

export default function Login() {
  const fetcher = useFetcher();
  const { defaultValue } = useLoaderData<typeof loader>();

  return (
    <LoginLayout>
      <Form
        schema={schema}
        className='rounded-lg border bg-card p-6 text-card-foreground shadow-panel'
        method='POST'
        defaultValue={defaultValue}
      >
        <Username autoFocus />
        <Password />
        <StaySignedIn />

        <div className='mt-6 flex justify-between'>
          <LoginButton isLoading={fetcher.state !== 'idle'} />
          <Link to={$path('/login/reset')}>
            <Button type='button' variant='ghost' isLoading={fetcher.state !== 'idle'}>
              <Trans ns={ns} i18nKey='dialog.trigger'>
                Reset password
              </Trans>
            </Button>
          </Link>
        </div>
      </Form>

      <ExternalLoginProviders />
    </LoginLayout>
  );
}
