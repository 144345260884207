import { useLoaderData } from '@remix-run/react';
import { Trans } from 'react-i18next';

import type { authenticationMethods } from '~/api';

import { Spacer } from '~/components/ui/spacer';

import { LoginGoogle } from './login-google';
import { LoginMicrosoft } from './login-microsoft';

const ns = 'login' satisfies Ns;
export const ExternalLoginProviders = () => {
  const { authMethods } = useLoaderData<{
    authMethods: Awaited<ReturnType<typeof authenticationMethods>>;
  }>();

  const hasMicrosoft = authMethods.includes('Microsoft');
  const hasGoogle = authMethods.includes('Google');

  const any = hasMicrosoft || hasGoogle;
  const size = 'sm';

  return any ? (
    <>
      <Spacer size={size} />

      <div className='grid grid-flow-col grid-cols-3 gap-3'>
        <hr className='m-auto w-full' />
        <span className='text-center'>
          <Trans ns={ns} i18nKey='alternate-logins'>
            or login with
          </Trans>
        </span>
        <hr className='m-auto w-full' />
      </div>

      <Spacer size={size} />

      <div className='grid grid-flow-col grid-cols-3 gap-3'>
        {hasMicrosoft && <LoginMicrosoft className='only:col-start-2' />}
        {hasGoogle && <LoginGoogle className='only:col-start-2' />}
      </div>
    </>
  ) : null;
};
