import { useLocation } from '@remix-run/react';
import { Trans } from 'react-i18next';

import { Button } from '~/components/ui/button';

import { ApplicationIcon } from '~/components/application-icons';

const ns = 'login' satisfies Ns;
export const LoginGoogle = ({ className, isLoading }: { className?: string; isLoading?: boolean }) => {
  const location = useLocation();

  return (
    <Button className={className} variant='outline' icon={<ApplicationIcon icon='google' />} isLoading={isLoading}>
      <a href={`/oauth/Google?redirectUri=${location.pathname}`}>
        <Trans ns={ns} i18nKey='button.google'>
          Google
        </Trans>
      </a>
    </Button>
  );
};
