import { Trans } from 'react-i18next';

import { Button } from '~/components/ui/button';

import { ApplicationIcon } from '~/components/application-icons';

const ns = 'login' satisfies Ns;
export const LoginButton = ({ isLoading }: Readonly<{ isLoading?: boolean }>) => (
  <Button id='login' variant='default' isLoading={isLoading} type='submit' icon={<ApplicationIcon icon='login' />}>
    <Trans ns={ns} i18nKey='button.default'>
      Login
    </Trans>
  </Button>
);
