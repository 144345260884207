import { cn } from '~/libs/utils';

import { Checkbox } from '~/components/ui/checkbox';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form';

import { useIsRequired } from '../../form-atom';
import type { CheckboxProps, FieldProps } from './types';

export type CheckboxFieldProps = Omit<FieldProps & CheckboxProps, 'type'>;
export const CheckboxField = ({
  label,
  helpText,
  name,
  defaultValue,
  className,
  ...props
}: Readonly<CheckboxFieldProps>) => {
  const isRequired = useIsRequired(name);
  return (
    <FormField
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormItem className={cn('flex flex-row items-start space-x-3 space-y-0 py-4', className)}>
          <FormControl>
            <Checkbox {...props} type='button' checked={field.value} onCheckedChange={field.onChange} {...field} />
          </FormControl>

          <div className='space-y-1 leading-none'>
            <FormLabel>
              {label}
              {isRequired && <span className='text-destructive'> *</span>}
            </FormLabel>
            {helpText && <FormDescription>{helpText}</FormDescription>}
            <FormMessage />
          </div>
        </FormItem>
      )}
    />
  );
};
