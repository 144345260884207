import { useLocation } from '@remix-run/react';
import { useState } from 'react';
import { Trans } from 'react-i18next';

import { Button } from '~/components/ui/button';

import { ApplicationIcon } from '~/components/application-icons';

const ns = 'login' satisfies Ns;
export const LoginMicrosoft = ({ className, isLoading }: { className?: string; isLoading?: boolean }) => {
  const location = useLocation();
  const [isClicked, setIsClicked] = useState(false);
  return (
    <Button
      className={className}
      variant='outline'
      icon={<ApplicationIcon icon='microsoft' />}
      isLoading={isLoading || isClicked}
    >
      <a
        data-testid='login-microsoft'
        href={`/oauth/Microsoft?redirectUri=${location.pathname}`}
        onClick={() => setIsClicked(true)}
      >
        <Trans ns={ns} i18nKey='button.microsoft'>
          Microsoft
        </Trans>
      </a>
    </Button>
  );
};
